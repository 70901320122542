import z from 'zod';
import type { ENERGY_UNITS } from './enums';

export const undefinedSchema = z.undefined();

export const blob = z.instanceof(Blob);

export const coordinatesSchema = z.object({
  longitude: z.number(),
  latitude: z.number(),
});

export const countrySchema = z.object({
  name: z.string(),
  code: z.string(),
  coordinates: coordinatesSchema,
  timezone: z.string().optional(),
});
export type Country = z.infer<typeof countrySchema>;

// Value unit metric schema
export const valueUnitMetricSchema = z.object({
  value: z.number(),
  unit: z.custom<keyof typeof ENERGY_UNITS>(),
});

// Compare Schemas

export const matchingOverviewSchema = z.object({
  consumption: z.number(),
  generation: z.number(),
  generationAllocated: z.number(),
  generationUnallocated: z.number(),
  matchingGenerationTotal: z.number(),
  matchingGenerationAllocated: z.number(),
  percentageMatchingGenerationAllocated: z.number(),
  matchingGenerationUnallocated: z.number(),
  percentageMatchingGenerationUnallocated: z.number(),
  excessGenerationTotal: z.number(),
  excessGenerationAllocated: z.number(),
  excessGenerationUnallocated: z.number(),
  unmatchedConsumption: z.number(),
});

export const matchingOverviewValueSchema = z.number().or(z.object({ value: z.number(), percentage: z.number() }));
export const carbonOverviewValueSchema = z
  .number()
  .or(z.string())
  .or(z.object({ value: z.number(), percentage: z.number() }));

export const overviewGridRowSchema = z
  .object({
    dataType: z.string(),
    unit: z.string(),
    overviewGlobalMatchingFiveMinute: matchingOverviewValueSchema,
    overviewGlobalMatchingFifteenMinute: matchingOverviewValueSchema,
    overviewGlobalMatchingThirtyMinute: matchingOverviewValueSchema,
    overviewGlobalMatchingHourly: matchingOverviewValueSchema,
    overviewGlobalMatchingDaily: matchingOverviewValueSchema,
    overviewGlobalMatchingMonthly: matchingOverviewValueSchema,
    overviewGlobalMatchingQuarterly: matchingOverviewValueSchema,
    overviewGlobalMatchingUkCompliancePeriod: matchingOverviewValueSchema,
    overviewGlobalMatchingYearly: matchingOverviewValueSchema,
    overviewGlobalMatchingWholePeriod: matchingOverviewValueSchema,
  })
  .partial();

export const overviewGridSchema = z.array(overviewGridRowSchema);
export type OverviewGridData = z.infer<typeof overviewGridSchema>;
export type OverviewGridRow = z.infer<typeof overviewGridRowSchema>;

export const consumerCarbonGridRowSchema = z
  .object({
    dataType: z.string(),
    unit: z.string(),
    carbonContentOverviewFiveMinute: carbonOverviewValueSchema,
    carbonContentOverviewFifteenMinute: carbonOverviewValueSchema,
    carbonContentOverviewThirtyMinute: carbonOverviewValueSchema,
    carbonContentOverviewHourly: carbonOverviewValueSchema,
    carbonContentOverviewDaily: carbonOverviewValueSchema,
    carbonContentOverviewMonthly: carbonOverviewValueSchema,
    carbonContentOverviewYearlyUkCompliancePeriod: carbonOverviewValueSchema,
    carbonContentOverviewYearly: carbonOverviewValueSchema,
  })
  .partial();
export const consumerCarbonGridSchema = z.array(consumerCarbonGridRowSchema);

export type ConsumerCarbonGridData = z.infer<typeof consumerCarbonGridSchema>;
export type ConsumerCarbonGridRow = z.infer<typeof consumerCarbonGridRowSchema>;

export const technologySchema = z.object({
  name: z.string(),
  color: z.string(),
  excessColor: z.string(),
  index: z.number(),
});
export type Technology = z.infer<typeof technologySchema>;

export const metricPrefixesName = z.enum(['pico', 'nano', 'micro', 'milli', '', 'kilo', 'mega', 'giga', 'tera']);
export type METRIC_PREFIX_NAME = z.infer<typeof metricPrefixesName>;

export interface METRIC_PREFIX {
  symbol: string;
  base10: number;
}
export const METRIX_PREFIXES: { [key in METRIC_PREFIX_NAME]: METRIC_PREFIX } = {
  pico: {
    symbol: 'p',
    base10: -12,
  },
  nano: {
    symbol: 'n',
    base10: -9,
  },
  micro: {
    symbol: 'μ',
    base10: -9,
  },
  milli: {
    symbol: 'm',
    base10: -6,
  },
  '': {
    symbol: '',
    base10: 0,
  },
  kilo: {
    symbol: 'k',
    base10: 3,
  },
  mega: {
    symbol: 'M',
    base10: 6,
  },
  giga: {
    symbol: 'G',
    base10: 9,
  },
  tera: {
    symbol: 'T',
    base10: 12,
  },
};

// File upload Schemas

export const fileUploadErrorSchema = z.object({
  errorCode: z.string(),
  lineNumber: z.number().nullable(),
  columnNumber: z.number().nullable(),
  errorMessage: z.string().nullable(),
});

export type FileUploadError = z.infer<typeof fileUploadErrorSchema>;

export const fileUploadReportSchema = z.object({
  periodStart: z.string(),
  periodEnd: z.string(),
  totalEnergyQuantity: z.string(),
  countMeteringPoint: z.number(),
});

export type FileUploadReport = z.infer<typeof fileUploadReportSchema>;

export const uploadImportFileSchema = z.object({
  id: z.string().uuid(),
});

export const uploadedImportFileSchema = z.object({
  id: z.string().uuid(),
  filename: z.string(),
  errors: z.array(fileUploadErrorSchema).nullable(),
  report: fileUploadReportSchema.nullable(),
  uploadedAt: z.string(),
  processingFinished: z.boolean(),
  processingSuccessful: z.boolean().nullable(),
});

export type UploadedImportFile = z.infer<typeof uploadedImportFileSchema>;
